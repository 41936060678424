import React from "react";
import css from "./ContentLink.module.css";

export default function ContentLink({ variante, extrait, children, typePage }) {
  const textClassnames = `
    ${css["c-ContentLink__extrait"]} 
    ${variante === "inBlog" && css["c-ContentLink__extrait__inBlog"]}
  `;

  const videoClassnames = `${css["c-ContentLink__extrait--video"]} u-box-shadow`;

  let classNames = textClassnames;

  if (typePage !== "text") {
    classNames = `${classNames} ${videoClassnames}`;
  }

  return <div className={classNames}>{children}</div>;
}
