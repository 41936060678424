import React from "react";
import ContentLink from "@/Widgets/Blog/ContentLink";
import ContentTextExtrait from "@/Widgets/Blog/ContentTextExtrait";
import ContentVideoExtrait from "@/Widgets/Blog/ContentVideoExtrait";

export default function ContentExtrait({
  rubriqueSlug,
  extrait,
  typePage,
  variante,
}) {
  // if(rubriqueSlug==='test-de-developpement'){
  //   console.log(extrait)
  // }
  return (
    <ContentLink variante={variante} extrait={extrait} typePage={typePage}>
      {typePage === "text" && (
        <ContentTextExtrait
          variante={variante}
          rubriqueSlug={rubriqueSlug}
          extrait={extrait}
        />
      )}
      {typePage !== "text" && (
        <ContentVideoExtrait
          rubriqueSlug={rubriqueSlug}
          extrait={extrait}
          variante={variante}
        />
      )}
    </ContentLink>
  );
}
