import React, { useState } from "react";
import Image from "next/image";
import { IMAGE_FALLBACK_URL } from "@/Constants/strapiApi";

export default function ImageWithFallback({
  src = IMAGE_FALLBACK_URL,
  fallbackSrc = IMAGE_FALLBACK_URL,
  width = 0,
  height = 0,
  layout,
  ...rest
}) {
  const [imgSrc, setImgSrc] = useState(src);

  return (
    // eslint-disable-next-line jsx-a11y/alt-text
    <Image
      {...rest}
      src={imgSrc || fallbackSrc}
      width={imgSrc === fallbackSrc ? null : width}
      height={imgSrc === fallbackSrc ? null : height}
      layout={imgSrc === fallbackSrc ? "fill" : layout}
      placeholder={"Image not found"}
      key={imgSrc || fallbackSrc}
      onError={() => {
        setImgSrc(fallbackSrc);
      }}
    />
  );
}
