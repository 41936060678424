import React from "react";
import { Image } from "@/Shared/ImageWithFallback";
import ReactPlayer from "react-player/lazy";

// https://www.npmjs.com/package/react-player#config-prop
/**
 * @function
 * @description Element jouant permettant d'intégrer un lecteur de vidéo sur la base d'une URL donnée.
 * @todo https://github.com/CookPete/react-player/issues/508
 * Failed to execute 'postMessage' on 'DOMWindow': The target origin provided ('https://www.youtube.com')
 * does not match the recipient window's origin ('http://localhost:3000').
 * @param url
 * @param rest
 * @returns {JSX.Element}
 * @constructor
 */
export default function VideoPlayer({ url, ...rest }) {
  return (
    <>
      {url ? (
        <ReactPlayer or='true' url={url} {...rest} />
      ) : (
        <Image
          layout={"responsive"}
          alt={"No video url provided"}
          height={rest.height}
          width={rest.width}
        />
      )}
    </>
  );
}
