import Head from "next/head";
import { SEO } from "@/Constants/seo";
import React from "react";
import favIcon from "@/Public/favicon.ico";

const titlePrepend = "WeekandGO | ";
const Seo = ({ seo }) => {
  let currentUrl = "https://www.weekandgo.com/";
  if (typeof window !== "undefined")
    currentUrl = window.location.href || currentUrl;

  const defaultSeo = { ...SEO["index"] };

  let fullSeo = {};

  if (seo) {
    fullSeo = {
      ...defaultSeo,
      ...seo,
      metaDescription: seo?.metaDescription || defaultSeo.metaDescription,
      metaTitle: seo?.metaTitle
        ? `${titlePrepend}${seo.metaTitle}`
        : `${titlePrepend}${defaultSeo.metaTitle}`,
      metaQuote: seo.metaQuote || null,
      metaKeywords: seo.metaKeywords || defaultSeo.metaKeywords,
      shareImage: seo?.shareImage || favIcon.src,
    };
  }

  // https://developers.google.com/search/docs/advanced/crawling/special-tags?visit_id=637832956847485469-3519641318&rd=1
  // https://ogp.me/
  return (
    <Head>
      <title>{fullSeo.metaTitle}</title>

      {fullSeo.canonicalUrl ? (
        <link rel='canonical' href={fullSeo.canonicalUrl} />
      ) : null}
      <meta name='author' lang='fr' content='Leb Communication' />
      <meta name='copyright' content='WeekandGO' />
      <meta name='description' content={fullSeo.metaDescription} />
      <meta name='identifier-url' content='www.weekandgo.com' />
      <meta name='image' content={fullSeo.shareImage} />
      <meta name='keywords' content={fullSeo.metaKeywords} />
      <meta name='publisher' content='WeekandGO' />
      {fullSeo.metaQuote ? (
        <meta name='quote' content={fullSeo.metaQuote} />
      ) : null}
      <meta name='reply-to' content='' />
      <meta name='revisit-after' content='7' />
      <meta name='robots' content='index, follow' />
      <meta name='title' content={fullSeo.metaTitle} />
      <meta name='type' content='website' />
      <meta name='url' content={currentUrl} />

      <meta name='twitter:title' content={fullSeo.metaTitle} />
      <meta name='twitter:description' content={fullSeo.metaDescription} />
      <meta name='twitter:image' content={fullSeo.shareImage} />

      <meta property='og:title' content={fullSeo.metaTitle} />
      <meta property='og:type' content='website' />
      <meta property='og:url' content={currentUrl} />
      <meta property='og:description' content={fullSeo.metaDescription} />
      <meta property='og:hashtag' content={fullSeo.metaDescription} />
      <meta property='og:image' content={fullSeo.shareImage} />
      {/*<meta property='og:image:type' content='image/*' />*/}
      {/*<meta property='og:locale' content='fr_FR' />*/}
      {/*<meta property='og:locale:alternate' content='fr_BG' />*/}
      {/*<meta property='og:locale:alternate' content='en_US' />*/}
      {/*<meta property='og:locale:alternate' content='en_GB' />*/}
      {/*<meta property='og:quote' content={fullSeo.metaDescription} />*/}
      {/*<meta property='og:site_name' content='WeekandGO' />*/}
    </Head>
  );
};

export default Seo;
