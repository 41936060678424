/**
 * @author Sébastien NOBOUR
 * @namespace Constants
 * @module Constantes Seo
 * @description Fichier de constantes de valeurs seo. A utiliser dans le composant Seo afin de remplir
 * les tags des pages avec du seo.
 */

import { PAGE_RECHERCHE_VEHICULE, PAGE_VENDEURS } from "@/Constants/pageRoutes";

/**
 * @const
 * @type {string}
 * @description Non exporté
 */
const defaultMetaDescription =
  "WeekandGO, c'est le site pour trouver votre futur camping-car ou fourgon aménagé " +
  "parmi tous les véhicules neufs ou d’occasion en vente sur le marché, auprès de particuliers et de professionnels";

/**
 * @constant
 * @type {string}
 * @description Non exporté
 */
const defaultMetaKeywords =
  "trouver, recherche, chercher, comparer, achat, vente, camping-car, camping car, " +
  "fourgon, fourgon aménagé, caravane, prix, acheter, vendre, entre particulier.";

/**
 * @constant
 * @type {{vendeurs: {metaKeywords: string, metaTitle: string, shareImage, metaDescription: string}, notFound404: {metaKeywords: string, metaTitle: string, metaDescription: string}, espacePro: {mesConcessions: {metaKeywords: string, metaTitle: string, metaDescription: string}, concession: {suppression: {metaKeywords: string, metaTitle: string, metaDescription: string}, edition: {metaKeywords: string, metaTitle: string, metaDescription: string}, creation: {metaKeywords: string, metaTitle: string, metaDescription: string}}, maConcession: {metaKeywords: string, metaTitle: string, metaDescription: string}, flux: {metaKeywords: string, metaTitle: string, metaDescription: string}}, index: {metaKeywords: string, metaTitle: string, shareImage, metaDescription: string}, "recherche-vehicule-loisirs": {metaKeywords: string, metaTitle: string, shareImage, metaDescription: string}}}
 */
export const SEO = {
  index: {
    metaTitle:
      "Camping-cars, fourgons aménagés et vans aménagés à vendre - Véhicules neufs ou d'occasion",
    metaDescription:
      "Parcourez nos annonces et découvrez de nombreux camping-cars et autres véhicules de loisirs, neufs ou d'occasion, disponibles à  la vente.",
    metaKeywords:
      "achat, vente, camping-car, camping car, fourgon, fourgon aménagé, caravane, prix, acheter, " +
      "vendre, particulier à particulier, concession, concessionnaire, profilé, intégral, profilés, intégraux, " +
      "capucines, capucine, toit relevable, van, vans, lit central, lit pavillon, lit jumeaux, " +
      "lit transversal, lit d'appoint, lit superposé, neuf, occasion",
  },
  "recherche-vehicule-loisirs": {
    metaTitle:
      "Annonces de camping-cars et véhicules de loisirs disponibles à la vente : neufs ou d’occasion",
    metaDescription:
      "Parcourez nos annonces de camping-cars et de véhicules de loisirs à vendre, qu'ils soient neufs ou d'occasion. Trouvez votre compagnon de voyage idéal pour des moments inoubliables.",
    metaKeywords: defaultMetaKeywords,
    canonicalUrl: `${process.env.NEXT_PUBLIC_SITE_URL}${PAGE_RECHERCHE_VEHICULE}`,
  },
  concept: {
    metaTitle: "Le concept WeekandGO",
    metaDescription: defaultMetaDescription,
    metaKeywords: defaultMetaKeywords,
  },
  "connexion-inscription": {
    metaTitle: "Connexion / Inscription",
    metaDescription: defaultMetaDescription,
    metaKeywords:
      "achat, vente, camping-car, camping car, fourgon, fourgon aménagé, caravane, prix, acheter, " +
      "vendre, particulier à particulier, concession, concessionnaire, profilé, intégral, profilés, intégraux, " +
      "capucines, capucine, toit relevable, van, vans, lit central, lit pavillon, lit jumeaux, " +
      "lit transversal, lit d'appoint, lit superposé, neuf, occasion",
  },
  vendeurs: {
    metaTitle: "Trouver un concessionnaire",
    metaDescription: defaultMetaDescription,
    metaKeywords:
      "achat, vente, camping-car, camping car, fourgon, fourgon aménagé, caravane, prix, acheter, " +
      "vendre, particulier à particulier, concession, concessionnaire, profilé, intégral, profilés, intégraux, " +
      "capucines, capucine, toit relevable, van, vans, lit central, lit pavillon, lit jumeaux, " +
      "lit transversal, lit d'appoint, lit superposé, neuf, occasion",
    canonicalUrl: `${process.env.NEXT_PUBLIC_SITE_URL}${PAGE_VENDEURS}`,
  },
  notFound404: {
    metaTitle: "pas de résultat",
    metaDescription:
      "WeekandGO, découvrez toutes les annonces de camping-cars, fourgons et caravanes " +
      "publiées par des professionnels et des particuliers",
    metaKeywords: defaultMetaKeywords,
  },
  espacePro: {
    flux: {
      metaTitle: "Mon flux",
      metaDescription: "Mon flux",
      metaKeywords: defaultMetaKeywords,
    },
    mesConcessions: {
      metaTitle: "Mes concessions",
      metaDescription: "Mes concessions",
      metaKeywords: defaultMetaKeywords,
    },
    maConcession: {
      metaTitle: "Ma concession",
      metaDescription: "Ma concession",
      metaKeywords: defaultMetaKeywords,
    },
    concession: {
      creation: {
        metaTitle: "Création d'une concession",
        metaDescription: "Création d'une concession",
        metaKeywords: defaultMetaKeywords,
      },
      edition: {
        metaTitle: "Édition des détails de la concession",
        metaDescription: "Édition des détails de la concession",
        metaKeywords: defaultMetaKeywords,
      },
      suppression: {
        metaTitle: "Supression de la concession",
        metaDescription: "Suppression de la concession",
        metaKeywords: defaultMetaKeywords,
      },
    },
  },
  espaceParticulier: {
    mesRecherchesSauvegardees: {
      metaTitle: "Mes recherches sauvegardées",
      metaDescription: "Mes recherches sauvegardées",
      metaKeywords: defaultMetaKeywords,
    },
  },
};
