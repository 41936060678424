import React from "react";
import css from "./ContentVideoExtrait.module.css";
import cssExtrait from "../CMSExtrait/CMSExtrait.module.css";
import Svg from "@/Shared/Svg";
import Link from "next/link";
import VideoPlayer from "@/Shared/VideoPlayer/VideoPlayer";

export default function ContentVideoExtrait({
  rubriqueSlug,
  variante,
  extrait,
}) {
  return (
    <>
      <div className={`${css["c-extraitsCMS__video"]}`}>
        <div className={"u-frame-video l-fix-w js__gsap__video"}>
          <div className={"u-frame-video-inside"}>
            {/*<iframe*/}
            {/*  width="560"*/}
            {/*  height="315"*/}
            {/*  title={extrait.name}*/}
            {/*  frameBorder="0"*/}
            {/*  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"*/}
            {/*  allowFullScreen=""*/}
            {/*>*/}
            {/*</iframe>*/}
            <VideoPlayer
              url={
                extrait.body.find(
                  section => section.__component === "sections.video"
                )?.link
              }
              controls={true}
              width='100%'
              height='auto'
            />
          </div>
        </div>
      </div>

      <div className={`${css["c-extraitsCMS__video-caption"]}`}>
        <Svg selection='ico-play' />
        <div className={`${css["c-extraitsCMS__video-caption__text"]}`}>
          {variante !== "inBlog" && (
            <Link href={`/blog/${rubriqueSlug}/`}>
              <a
                className={`${cssExtrait["c-extraitsCMS__extrait__cat"]} ${cssExtrait["c-extraitsCMS__extrait__cat--video"]}`}
              >
                {extrait.category.name}
              </a>
            </Link>
          )}
          <h3 className={"f-primary f-primary--700"}>
            <Link href={`/blog/${rubriqueSlug}/${extrait.slug}`}>
              <a className={`${css["c-extraitsCMS__extrait__titre"]}`}>
                {extrait.title}
              </a>
            </Link>
          </h3>
        </div>
      </div>
    </>
  );
}
