import React from "react";
import Link from "next/link";
import css from "./ContentTextExtrait.module.css";
import cssExtrait from "../CMSExtrait/CMSExtrait.module.css";
import Btn from "@/Shared/Ui/Btn";
import uiBtn from "@/Shared/Ui/Btn/Btn.module.css";

export default function ContentTextExtrait({
  variante,
  rubriqueSlug,
  extrait,
}) {
  extrait.description =
    extrait.description.length > 224
      ? `${extrait.description.substr(0, 224)}...`
      : extrait.description;

  return (
    <>
      {variante !== "inBlog" && (
        <Link href={`/blog/${rubriqueSlug}/`}>
          <a className={`${cssExtrait["c-extraitsCMS__extrait__cat"]}`}>
            {extrait.category.name}
          </a>
        </Link>
      )}
      <Link
        className={`${css["c-extraitsCMS__extrait__container"]}`}
        href={`/blog/${rubriqueSlug}/${extrait.slug}`}
      >
        <>
          <h3 className={"f-primary f-primary--700"}>
            <Link
              href={`/blog/${rubriqueSlug}/${extrait.slug}`}
              passHref={true}
            >
              <a>
                <div className={`${css["c-extraitsCMS__extrait__titre"]}`}>
                  {extrait.title}
                </div>
              </a>
            </Link>
          </h3>
          <p>{extrait.description}</p>
          <div className={`${css["c-extraitsCMS__extrait__links"]}`}>
            <Btn
              type='routeLink'
              path={`/blog/${rubriqueSlug}/${extrait.slug}`}
              label="Lire l'article"
              className={`
              ${uiBtn["u-btn-primary"]} ${uiBtn["u-btn-small"]} 
              ${uiBtn["u-btn-hoverSlide"]} ${uiBtn["u-btn-hoverSlide--secondary"]}
            `}
            />
          </div>
        </>
      </Link>
    </>
  );
}
