import React from "react";
// import PropTypes from 'prop-types';
import css from "./CMSMosExtraits.module.css";
import Btn from "@/Shared/Ui/Btn";
import uiBtn from "@/Shared/Ui/Btn/Btn.module.css";

const CMSMosExtraits = ({ variante = "inBlog", children }) => {
  return (
    <>
      <div
        className={`${css["c-extraitsCMS"]} ${
          variante === "inBlog" && css["c-extraitsCMS__inBlog"]
        }`}
      >
        {children}
      </div>
      {variante === "inPasserelle" && (
        <div className='u-btn-centrage-largeur'>
          <Btn
            type='routeLink'
            path={"/blog"}
            order='icotext'
            className={`
                ${uiBtn["u-btn-text"]} ${uiBtn["u-btn-textico"]} 
                ${uiBtn["u-btn-hoverSlide"]} ${uiBtn["u-btn-hoverSlide--gris"]}
               `}
            label='Voir tous les articles'
            svg='ico-plus'
          />
        </div>
      )}
    </>
  );
};

// CMSMosExtraits.propTypes = {
//   variante: PropTypes.string
// };

export default CMSMosExtraits;
